import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import {
    ArrowRight,
    BookOpen,
    Coins,
    Feather,
    Globe2,
    Loader,
    Users,
} from "lucide-react";
import * as React from "react";
import BetaNotice from "../components/BetaNotice/BetaNotice";
import Section from "../components/Section/Section";
import Seo from "../components/SEO/SEO";

import Layout from "../layout/default";
import * as styles from "./index.module.scss";

const IndexPage = () => {
    return (
        <Layout noSpaceBehindNav={true}>
            <Seo
                title="Welcome"
                seoTitle="MinervaTools"
                description={
                    "Discover the easy way to manage your Minecraft server worlds!"
                }
            />
            <div className={styles.hero}>
                <StaticImage
                    className={styles.heroLogo}
                    alt="MinervaTools Logo"
                    src="../images/Minerva.png"
                    width={150}
                />
                <span className={styles.heroTagline}>
                    Discover the easy new way to manage your Minecraft worlds!
                </span>
            </div>
            <BetaNotice />
            <Section className={styles.featuresSection}>
                <div className={styles.featureList}>
                    <div className={styles.feature}>
                        <div className={styles.featureIcon}>
                            <Globe2 />
                        </div>
                        <span className={styles.featureTitle}>
                            Intuitive Web Interface
                        </span>
                        <span>
                            MinervaSpigot comes with MinervaPanel, an intuitive
                            web interface allowing your builders to up- and
                            download worlds, without ever needing FTP-access.
                        </span>
                    </div>
                    <div className={styles.feature}>
                        <div className={styles.featureIcon}>
                            <Feather />
                        </div>
                        <span className={styles.featureTitle}>
                            Light on Memory
                        </span>
                        <span>
                            MinervaSpigot automatically unloads worlds when
                            they're not needed, saving on memory you can use for
                            better things than containing worlds noone uses.
                        </span>
                    </div>
                    <div className={styles.feature}>
                        <div className={styles.featureIcon}>
                            <Loader />
                        </div>
                        <span className={styles.featureTitle}>Modern</span>
                        <span>
                            Development on MinervaTools started in 2021 from
                            scratch. During development the needs of builders
                            were put first in close colaboration with
                            experienced Minecraft Marketplace Partners.
                        </span>
                    </div>
                </div>
                <Link
                    className={styles.buttonLight}
                    to={"/features"}
                    title={"Features"}
                >
                    Explore all Features <ArrowRight />
                </Link>
            </Section>
            <Section className={styles.whySection}>
                <h1>Why MinervaTools?</h1>
                <div>
                    <p>
                        MinervaTools is a brand new approach to Minecraft world
                        handling, with MinervaSpigot written from scratch with
                        large builder servers in mind. This is a big advantage
                        over comparable solutions, as most other plugins were
                        written for small personal servers.
                    </p>
                    <p>
                        It works great with all sizes of servers. We've
                        successfully deployed the plugin both on small servers
                        with just 10 worlds, and on the massive build server of
                        one of the 5 biggest Minecraft Marketplace sellers.
                    </p>
                </div>
            </Section>
            <Section className={styles.featuresSection}>
                <h1>Interested? Learn more!</h1>
                <div className={styles.featureList}>
                    <div className={styles.feature}>
                        <div className={styles.featureIcon}>
                            <Coins />
                        </div>
                        <span className={styles.featureTitle}>Pricing</span>
                        <span>
                            We're currently in closed beta, but we plan to go
                            public in early 2022. How about checking out the
                            prices and finding out if MinervaTools will be right
                            for you?
                        </span>

                        <Link
                            className={styles.buttonLight}
                            to={"/pricing"}
                            title={"Pricing"}
                        >
                            Check out Pricing <ArrowRight />
                        </Link>
                    </div>
                    <div className={styles.feature}>
                        <div className={styles.featureIcon}>
                            <Users />
                        </div>
                        <span className={styles.featureTitle}>
                            The who and why
                        </span>
                        <span>
                            MinervaTools has been born out of a need for a
                            intuitive world management plugin which could help
                            save memory. Meet the dev and find out what's the
                            motivation behind MinervaTools!
                        </span>

                        <Link
                            className={styles.buttonLight}
                            to={"/about"}
                            title={"About"}
                        >
                            Meet the Team <ArrowRight />
                        </Link>
                    </div>
                    <div className={styles.feature}>
                        <div className={styles.featureIcon}>
                            <BookOpen />
                        </div>
                        <span className={styles.featureTitle}>
                            Read the docs
                        </span>
                        <span>
                            MinervaTools has a documentation page where you can
                            take a peak at how the components work and what
                            commands are available.
                        </span>

                        <a
                            className={styles.buttonLight}
                            href={"https://docs.minervatools.net"}
                            title={"Documentation"}
                        >
                            docs.minervatools.net <ArrowRight />
                        </a>
                    </div>
                </div>
            </Section>
        </Layout>
    );
};

export default IndexPage;
